input[type="checkbox"]:checked {
    color: #fff;
}

.dd-item.open, input[type="checkbox"]:checked {
    border-color: #B3B1B4;
    background-color: #B3B1B4;
    box-shadow: none;
}

input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

button, input {
    overflow: visible;
}

.bp-checkbox-button__original {
    opacity: 0;
    outline: 0;
    position: absolute;
    margin: 0;
    z-index: -1;

}

.bp-checkbox-button, .bp-checkbox-button__inner {
    display: inline-block;
    position: relative;
    /* margin-right : 30px; */
    margin-right: 3px;
}

.bp-checkbox-button:last-child .bp-checkbox-button__inner {
    border-radius: 5px;
}

/*.bp-checkbox-button:last-child .bp-checkbox-button__inner {
    margin-right : 0px;
}*/

.bp-checkbox-button.is-checked .bp-checkbox-button__inner {
    /*color: #000;*/
    color: rgba(255, 255, 255, 0.87);
    background-color: #B3B1B4;
    border-color: #B3B1B4;
        border-left-color: rgb(238, 192, 89);
    box-shadow: -1px 8px 56px -12px rgba(0,0,0,.75);
}

.two_home .bp-fast-checkbox-button__inner {
    box-shadow: -1px 8px 56px -12px rgba(0,0,0,.75);
    border: 0;
    margin: 5px;
    border-radius: 5px;
    padding: 14px;
   /* width: 45px;
    height: 47px;*/
    width: 58px;
    height: 47px;
    color: #fff;
    background: #6c757d;
    font-weight: 700;
    cursor: pointer;
}

.two_home .bp-checkbox-button__inner {
    /*box-shadow: -1px 8px 56px -12px rgba(0,0,0,.75);*/
    border: 0;
    margin: 5px;
    border-radius: 5px;
    padding: 14px;
    width: 50px;
    height: 47px;
    /*color: #3F9B43;*/
    color: #fff;
    /*background-color: #fff;*/
    background-color: var(--primarythirdcolor);
    /*color: #e0dddd;
    background: #1a1a1a;
        background-color: rgb(26, 26, 26);*/
 /*   font-weight: 700;*/
}

.bp-checkbox-button__inner {
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    border-left: 0;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    /* transition: all .3s cubic-bezier(.645,.045,.355,1); */
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 0;
}

.bp-checkbox-button__inner.disabled {
    /*background: #f7f2f2;*/
    /* background: #c65858; */
    background: #615858;
}


.two_home .numberProgessBar {
  /*  max-width: 480px;
    width: 41px;*/
    max-width: 420px;
    width: 41px;
}

.bp-progress-bar {
    /*padding-right: 50px;*/
    width: 100%;
    margin-left: -20px;
    box-sizing: border-box;
}
.bp-progress-bar, .bp-progress-bar__inner::after, .bp-progress-bar__innerText, .el-spinner {
    display: inline-block;
    vertical-align: middle;
}

.bp-progress {
    position: relative;
    line-height: 1;
}

.two_home .numberProgessBar .bp-progress-bar__outer {
    /* background-color: #d9d7d7; */
    background-color: #9c9797;
    height: 4px !important;
}

.two_home .bp-progress.is-success .bp-progress-bar__inner {
    /*background-color: #59bf61 !important;*/
    height: 4px !important;
}


.fixed-iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    transform: translateY(100%) rotate(-90deg);
    transform-origin: top left;
}


/*.iframe-desktop {
    
}*/

.iframe-mobile {
    display: block;
    width: 100vh;
    height: 100vw;
    border: none;
    transform: translateY(100vh) rotate(-90deg);
    transform-origin: top left;
}

/*.iframe-desktop {
    display: block;
    width: 100vh;
    height: 100vw;
    border: none;
    transform: translateY(100vh) rotate(-90deg);
    transform-origin: top left;
}*/