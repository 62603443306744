/* header */
.apz-header {
    padding: 8px 0;
}
.apz-nav-header {
    font-size: 22px;
    font-weight: 700;
}
.parent-flex {
    justify-content: flex-end;
}
header.top-bg {
    background: linear-gradient(#820203, #560001);
}
.mid-pocket {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #6F3033;
    padding: 0 15px 0 0;
    height: unset;
    /* margin-top: 8px; */
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #730000;
    box-shadow: 0px 0px 4px 0px #00000080;
    min-width: 120px;
}

.mid-pocket > span {
    padding: 0 5px 0 2px;
    font-size: 18px;
}
/* end header */

/* home */
.bg-noise {
    background: #640105;
    border: 1px solid #FE3B37;
}
.lotto {
    text-align: center;
}
.mt-30 {
    margin-top: 30px;
}
.mb-30 {
    margin-bottom: 30px;
}
.game-content {
    display: flex;
    text-align: center;
}
.game-wrap {
    display: flex;
    justify-content: center;
}
.mt-60 {
    margin-top: 60px;
}
.mb-100 {
    margin-bottom: 100px;
}
.mb-60 {
    margin-bottom: 60px;
}
.text-mar {
    margin: 0 20px;
    padding: 10px 0;
    background-image: url(/img/apz/border-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
}
.text-mar > p {
    background: linear-gradient(90deg, rgba(102, 0, 0, 0) 0%, #660000 50%, rgba(102, 0, 0, 0) 100%);
    padding: 7px 20px;
    font-size: 14px;
}
.text-mar > img {
    width: 24px;
    height: 25px;
    margin-top: 6px;
    margin-left: 13px;
}

.close-wrapper {
    margin-left: 0;
}

.close-wrapper .timer-wrap {
    padding: 5px 18px;
}
/* end home */

/* home lotto */
.lotto-wrapper {
    margin-bottom: 130px;
    margin-left: 6px;
}
.lotto-first {
    position: relative;
}
/* .grid-50.lotto-first {
    position: relative;
    justify-content: space-around;
} */

.timer-wrap {
    background: #680006;
    position: absolute;
    top: 34px;
    text-align: center;
    padding: 5px 10px 0 10px;
    border: 3px solid #F6D055;
    max-width: 100%;
    border-radius: 12px;
}

.timer-wrap .btn-text {
    padding: 5px 0;
}

span.timer {
    margin-top: 5px;
}

.last-timer {
    padding: 5px 0 5px;
    display: block;
}

.lotto-g-no {
    height: 70px;
    width: 74px;
    border-radius: 50%;
    display: inline-block;
    border: 3px solid #F6D055;
    text-align: center;
    padding: 10px 8px;
}
.lotto-page-content > a {
    background: #370202;
    min-height: 190px;
}
.close-section {
    justify-content: center;
}
.two-wrap.close-section-content {
    margin-right: 2px;
    width: 100%;
}
.three-wrap.close-section-content {
    margin-left: 2px;
    width: 100%;
}
/* .two-wrap .result-number {
    padding: 11px 31px;
}
.three-wrap .result-number {
    padding: 11px 20.5px;
} */
.two-wrap .result-number {
    width: 90px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.three-wrap .result-number {
    width: 90px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.close-section-content {
    box-shadow: 0px 0px 4px 0px #BDBDBD40;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
/* .timer-wrap {
    background: #680006;
    position: absolute;
    top: 34px;
    text-align: center;
    padding: 10px;
    border: 3px solid #F6D055;
    width: 183px;
    height: 125px;
    border-radius: 12px;
} */
span.timer, .timer-result {
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
}
p.last-total-no {
    text-align: center;
    padding-top: 30px;
}
.timer-wrap > h2 {
    padding-top: 5px;
}
.timer-wrap > span, .timer-wrap .closetime-text {
    font-size: 14px;
    color: #F7D055;
    font-weight: 600;
}
span.timer, .timer-result {
    display: block;
}
span.timer-result {
    color: #fff !important;
    font-size: 16px;
    font-weight: 600;
}
span.closetime-result {
    color: #F6C915;
}
.timer::before, .timer-result::before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    height: 1.4px;
    bottom: 0px;
    top: 30px;
    background: radial-gradient(100% 7111110.77% at 0% 50.01%, rgba(247, 208, 85, 0) 0%, #F7D055 49.48%, rgba(247, 208, 85, 0) 100%);
}
.timer-wrap > h2 {
    font-size: 28px;
}
.btn-text {
    padding: 16px 0;
    box-shadow: 0px -6px 4px 0px #00000029 inset;
    background: linear-gradient(180deg, #F6D055 0%, #EB9939 100%);
    border-radius: 9px;
    cursor: pointer;
}
.btn-section {
    background: none;
}
.timer-wrap .timer, .timer-wrap > h2, .btn-lotto, p.lotto-g-no, .single-shoot-game h3.single-slot, .single-shoot-game > p,
.random-no-wrapper .last-result-no, .random-number > p, a.btn-bet, h3.timer, .bottom-amount > a,
input.form-control.shoot-amount, .number-range > a, .apz-inner-no, h2.records-sub-title, a.totally-lists span, a.bet-total,
span.today-card-time, span.today-card-no, .right-card, h2.title-detail-no, 
h2.title-detail-time, .result-number, .title-result-section, p.choose-no-key, .timer-result, .rubin,
.lobby-check, p.bet-st-time {
    font-family: 'Rubik', sans-serif;
}
p.lotto-g-no {
    font-weight: 400;
    font-size: 16px;
    color: #E93A33;
    line-height: 1.4;
    text-transform: uppercase;
    text-shadow: -1px -1px 1px rgba(246,208,85,.8), -1px -1px 1px rgb(246 208 85 / 50%);
}
a.btn-lotto {
    font-size: 18px;
    font-weight: 700;
    text-shadow: -1px -2px 1px rgba(89,1,0,.8), -1px 1px 0px rgb(89 1 0 / 50%);
}

.apz-main-bg {
    background:  #370202
}

.lotto-page-content > a {
    width: 49.99%;
    float: left;
}
h3.result-number {
    font-size: 26px;
    color: #F9E14D;
    background: #370202;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}
p.title-result-section {
    font-size: 13px;
    font-weight: 500;
    padding: 5px 10px;
    background: #2B0000;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
.close-wrapper p.lotto-g-no, .close-wrapper .timer-wrap {
    border-color: #A48B3B;
}
.close-wrapper .timer-wrap > span, .close-wrapper .timer-wrap .closetime-text, .last-timer {
    color: #A48B3B;
}
.close-wrapper .timer::before {
    background: radial-gradient(100% 7111110.77% at 0% 50.01%, rgba(247, 208, 85, 0) 0%, #A78D39 49.48%, rgba(247, 208, 85, 0) 100%);
}
/* end home lotto */

/* random shoot */
.top-shoot {
    display: flex;
    align-items: center;
    padding: 10px 0;
    background: #640105;
    justify-content: space-between;
    width: 100%;
}

.rule-btn {
    margin-right: 20px;
}

.rule-btn .btn-bet {
    padding: 10px 20px;
}

button.inactive > div {
    display: inline-block;
    font-size: 22px;
}

h3.single-slot {
    font-size: 18px;
    color: #F6C915;
}

.single-shoot-game > p {
    font-size: 20px;
    font-weight: 700;
}

.rule-btn {
    text-align: right;
}

a.btn-bet, button.btn-bet, button.btn-bet-now {
    display: inline-block;
    padding: 12px 20px;
    box-shadow: 0px -6px 4px 0px #00000029 inset;
    background: linear-gradient(180deg, #F6D055 0%, #EB9939 100%);
    border-radius: 9px;
    text-shadow: -1px -2px 1px rgba(89,1,0,.8), -1px 1px 0px rgb(89 1 0 / 50%);
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
}
button.btn-bet {
    text-shadow: none;
    color: #2B0000;
}

button.btn-bet-now {
    border: none;
}

a.btn-rule {
    padding: 10px 20px;
    background: #370202;
    border-radius: 9px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
}

.random-no-wrapper {
    position: relative;
}

.result-img {
    position: relative;
    z-index: 1;
}

.random-no-wrapper .last-result-no {
    font-weight: 700;
    font-size: 26px;
    margin-top: -60px;
    color: #44EC0F;
    text-shadow: -1px -2px 1px rgba(89,1,0,.8), -1px 1px 0px rgb(156 0 22 / 80%);
}

.random-shoot-wrapper {
    padding: 60px 0px 10px 0px;
    margin: 0 30px;
    border: 6px solid;
    border-color: #780a0c;
    border-radius: 12px;
    margin-top: -20px;
    background: #2B0000;
}

.rolling-no {
    display: flex;
    position: relative;
    justify-content: flex-end;
}

.type-shoot-setting {
    position: relative;
}

p.type-shoot-number {
    position: absolute;
    top: 58px;
    left: 34px;
    font-weight: 600;
    /* color: #F6D055; */
    color : #fff;
    font-size: 20px;
}

.rolling-no::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 40px;
    left: 0;
    bottom: 15px;
    background: #780a0c;
    z-index: 0;
}

.rolling-no > div {
    z-index: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    vertical-align: middle;
}

.grid-30.random-number {
    padding: 0;
    margin: 0 4px;
}

.random-number > p {
    font-size: 20px;
    font-weight: 600;
    color: #F6D055;
    padding: 8px 0px;
    border: 1px solid #F6D055;
    border-radius: 10px;
    cursor: pointer;
}

p.backspace-img {
    padding-bottom: 4px;
    background: #980303;
}

p.random-text {
    font-size: 16px;
    color: #fff;
    background: linear-gradient(180deg, #820203 0%, #560001 100%);
}

p.random-text-mm {
    font-size: 16px;
    color: #fff;
    background: linear-gradient(180deg, #820203 0%, #560001 100%);
}

.random-shoot-wrapper .btn-bet {
    width: 100%;
    padding: 15px 0;
    font-size: 18px;
    border: none;
}

.random-calculator-no {
    padding: 0 10px;
}

.user-list-wrapper {
    margin: 30px;
    border: 3px solid #820203;
    border-radius: 10px;
}

.user-list {
    padding: 20px 0px;
    background: #640105;
}

.user-list-dark {
    background: #370202;
    padding: 20px 0;
}

.user-list-dark.flex.special-number {
    background: #ddd;
}

.special-number .table-content {
    color: #111;
}

.user-list-light {
    background: #2A0000;
    padding: 20px 0;
}

img.icon {
    vertical-align: middle;
    margin-right: 10px;
}

.rules-lightbox {
    position: relative;
}

.rules-closebtn {
    position: absolute;
    right: 0;
    top: -20px;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    width: 40px;
}

img.result {
    cursor: pointer;
}

p.rules-text {
    text-align: left;
}
/* end random shoot */

/* footer start */
.apz-gawli-footer {
    background-color: var(--apzbgcolor);

}
.apz-footer {
    background-color: #6B0E11;
    padding: 5px 0;
}
/* footer end */

/* 3d shoot set */
p.success-text, .btn-sus-text {
    font-size: 21px;
}
.btn-sus-text {
    color: #2B0000;
}
.enter-amount-wrap {
    margin: 20px;
    border: 2px solid #810102;
    padding: 20px 20px;
    border-radius: 12px;
    background: #370202;
}

.amount-title > h3.timer {
    display: inline-block;
    position: relative;
    padding: 0 10px;
    color: #EB9939;
}

h3.timer::before {
    top: 30px;
}

a.btn-lotto.btn-fast, a.btn-lotto.btn-reverse {
    font-size: 16px;
}

a.btn-lotto.btn-reverse {
    font-weight: 500;
}

.fast-chose-txt {
    font-size: 20px;
}

a.btn-lotto.btn-fast {
    font-weight: 400;
    padding: 12px 16px;
    background: #ddd;
    border-radius: 8px;
    text-shadow: none;
    background: #630004;
}

.choose_types a.btn-lotto.btn-bet-now {
    padding: 12px 90px;
    border: 1px solid #EB9939;
}

a.btn-lotto.btn-bet-now.inactive {
    background: none;
    border: 1px solid #F6D055;
    color: #F6D055;
}

a.btn-lotto.btn-reverse {
    font-weight: 500;
    padding: 13px 16px 16px 16px;
    text-shadow: none;
    border-radius: 8px;
    background: #630004;
}

.choose_types {
    display: block;
    margin-top: 50px;
    margin-bottom: 40px;
}

a.btn-lotto.btn-bet-now {
    padding: 12px 84px;
    border-radius: 8px;
    background: linear-gradient(180deg, #F6D055 0%, #EB9939 100%);
    box-shadow: 0px -6px 4px 0px #00000029 inset;
}

.form-group.min-amount {
    padding: 1px;
    background: linear-gradient(180deg, #F6D055 0%, #EB9939 100%);
    border-radius: 8px;
}

.bottom-amount > a {
    cursor: pointer;
    display: inline-block;
}

input.form-control.shoot-amount {
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 0;
    background: #1C0000;
    border-radius: 8px;
}

.min-amount > input[type=text] {
    padding-left: 20px;
    color: #F6D055;
}

.bottom-amount {
    position: relative;
}

.bottom-amount::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: -28px;
    left: 0;
    background: #640105;
}
.number-range {
    margin:30px 20px 0 20px;
}

.btn-no-range {
    font-size: 14px;
    font-weight: 500;
}

.selet-option {
    padding: 10px 10px;
    background: var(--aungparzaybtncolor);
    border-radius: 8px;
}

.selet-option > select {
    padding: 0 !important;
}

select.btn-no-range > option {
    background: var(--aungparzaybtncolor);
    color: #fff;
    font-size: 14px;
}

.number-range > a > span {
    color: #E2E2E2;
}

img.dropdown-icon {
    padding-left: 5px;
}

span.btn-no-keyboard {
    padding-top: 11px;
    padding-bottom: 11px;
}

a.btn-no-keyboard {
    background: #370202;
    border-radius: 8px;
}

a.btn-no-keyboard > span {
    font-size: 15px;
    padding: 12px 80px;
    vertical-align: middle;
    display: inline-block;
    background: #2B0000;
}

.apz-number-box span.apz-inner-no {
    background: #2B0000;
    color: #F6D055;
    font-weight: 500;
    border: 1px solid #F6D055;
}

.apz-number-box .is-checked .apz-inner-no {
    background: #F6D055;
    color: #fff;
    font-weight: 500;
    border: 1px solid #F6D055;
}

.apz-inner-no {
    display: inline-flex;
    justify-content: center;
}

input.form-control.shoot-amount::placeholder {
    color: #8F6D28;
}

.btn-rsuccess {
    padding: 12px 16px;
    border-radius: 8px;
    background: linear-gradient(180deg, #F6D055 0%, #EB9939 100%);
    box-shadow: 0px -6px 4px 0px #00000029 inset;
}

.apz-inner-no {
    line-height: 1.5;
}
/* end 3d shoot set */

/* bet record page */
h2.records-sub-title {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}

.total-lobby-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    background: #590100;
    border: 1px solid #F6D055;
    border-radius: 8px;
    padding: 20px;
}

span.lobby-list-no {
    display: block;
}

a.totally-lists {
    padding-left: 10px;
}

a.bet-total {
    margin-right: 10px;
    font-size: 40px;
    font-weight: 700;
    padding: 15px 40px;
    background: #2B0000;
    border-radius: 10px;
}

span.lobby-list-no, .btn-tab .tab-holder:hover, .btn-tab .tab-holder.active {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    text-shadow: -1px -1px 1px rgba(96,1,3,.8), -1px -1px 1px rgb(96 1 3 / 50%);
}

span.lobby-list-time {
    font-size: 20px;
    font-weight: 500;
    color: #F6C915;
}
/* end bet record page */

/* betting page start */
.rule-popup {
    background: #590100 !important;
}
.two-game-wrapper {
    margin: 10px;
    padding: 10px 20px;
    border: 2px solid #FE3B37;
    background: #640105;
    border-radius: 16px;
}

.two-game-wrapper .grid-100 {
    font-weight: 700;
    color: #F6C915;
    font-size: 20px;
}

.two-game-wrapper .btn, .two_home .two-game-wrapper .bp-fast-checkbox-button__inner {
    border-radius: 10px;
    background: #370202;
    box-shadow: none;
    border: none;
}

.two-game-wrapper .btn:hover, .two-game-wrapper span.bp-fast-checkbox-button__inner:hover {
    border-color: #F6D055;
    background: linear-gradient(180deg, #F6D055 0%, #EB9939 100%);
}

.btn-tab {
    margin: 0 10px;
    border: 1px solid #F6D055;
    border-radius: 12px;
}

.btn-tab .tab-holder {
    font-size: 20px;
    font-weight: 700;
    color: #F6D055;
    margin: 2px;
}

.btn-tab .tab-holder:hover, .btn-tab .tab-holder.active {
    border-radius: 10px;
    padding: 10px 0px;
    background: linear-gradient(180deg, #F6D055 0%, #EB9939 100%);
}
.child-choice-gp {
    flex-wrap: wrap;
}
/* betting page end */

/* results page start*/
.today-card-results .adm-card-body {
    height: inherit;
    padding: 0;
}

.resultbykey {
    clear: both;
}

.today-card-results .adm-card-body .left-card {
    line-height: 3rem;
    margin-left: 20px;
    margin-bottom: 20px;
}

.today-card-results {
    background-image: url(/img/apz/card1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 132px;
}

.onecard-result {
    height: inherit;
    line-height: 2rem;
}

span.today-card-no {
    display: block;
    font-size: 24px;
    font-weight: 700;
    color: #E93A33;
    text-shadow: -1px -1px 1px rgba(249,255,77,.8), -1px -1px 1px rgb(249 255 77 / 50%);
}

span.today-card-time {
    color: #F6C915;
    font-size: 18px;
    font-weight: 700;
    display: block;
}

.left-card {
    margin-left: 40px;
    margin-top: -10px;
}

img.win-img {
    margin-left: 30px;
}

.right-card {
    font-size: 20px;
    font-weight: 700;
    margin-top: -5px;
}
span.lobby-check {
    color: #F6C915;
}
.win-amount-status, .grand {
    font-size: 22px;
}
span.rubin {
    display: block;
    font-size: 21px;
}
small.win-amount-status {
    padding-left: 5px;
}
/* results page end*/

/* detail page start */
.detail-header {
    justify-content: space-between;
}

h2.title-detail-no {
    padding-left: 20px;
    font-size: 20px;
    font-weight: 700;
}

h2.title-detail-time {
    padding-right: 20px;
    font-weight: 500;
    font-size: 20px;
    color: #F6C915;
}

.table-wrap {
    margin: 30px 20px;
    text-align: center;
}

table.table-detail {
    width: 100%;
    border-collapse: collapse;
}

table.table-detail th {
    background: #640105;
}

table.table-detail tbody tr:nth-child(even), .user-list-dark:nth-child(even) {
    background: #2A0000;
}

table.table-detail th, table.table-detail td {
    border: none;
}

table.table-detail th {
    color: #F6C915;
}

.table-wrap {
    margin: 30px 20px;
    text-align: center;
    border: 3px solid #820203;
}

table.table-detail th, table.table-detail td {
    padding: 13px 20px;
}
.result-detail-content {
    margin: 20px;
    background: #2A0000;
    padding: 30px 20px;
}
p.bet-st-time {
    text-align: center;
}
.inner-column {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.inner-column.inner-second {
    margin-top: 0;
    margin-bottom: 20px;
}
span.text-result-detail {
    color: #F6C915;
}
/* detail page end */

.align-center.left.grid-40.parent-ui.pocket {
    margin-left: 60px;
}
.align-center.left.grid-20.parent.parent-flex {
    margin-left: 20px;
}
.align-center.left.grid-40.l1ogo.parent {
    margin-left: 50px;
}

/* responsive */
@media screen and (max-width: 575px) {
    .apz-main-bg .main-content {
        padding: 0;
    }
}

@media screen and (max-width: 475px) {
 

    .close-section-content .result-number {
        width: 83px;
        height: 50px;
    }
    .timer-wrap > h2 {
        font-size: 23px;
    }
    .lotto-wrapper {
        margin-bottom: 120px;
        margin-left: 5px;
    }
    p.title-result-section {
        font-size: 11px;
    }
    h3.result-number {
        font-size: 22px;
    }
    /* /aungparzay/shoot/:id start */
    .result-img img.result {
        width: 75%;
    }
    .random-no-wrapper .last-result-no {
        font-size: 24px;
        margin-top: -55px;
    }
    .rule-btn .btn-bet {
        padding: 10px 20px;
    }
    /* /aungparzay/shoot/:id end */
}

@media (max-width: 425px) {
    /* .close-section-content .result-number {
        width: 74px;
        height: 46px;
    }
    .close-wrapper .timer-wrap {
        padding: 5px 10px;
    } */
    .align-center.left.grid-20.parent.parent-flex img.pointer {
        height: 48px;
    }
    p.title-result-section {
        padding: 7.5px 0;
    }
    .align-center.left.grid-40.parent-ui.pocket {
        margin-left: 45px;
    }
    .close-section-content .result-number {
        width: unset;
        height: unset;
        padding: 12px 0;
    }
    .close-wrapper .timer-wrap {
        padding: 4.5px 8px;
        width: 84%;
    }
    p.type-shoot-number {
        font-size: 18px;
    }
    /* /aungparzay/shoot/:id start */
    img.gawli {
        width: 85px;
    }
    .random-number > p, .random-shoot-wrapper .btn-bet {
        font-size: 16px;
    }
    p.random-text {
        font-size: 14px;
    }
    p.backspace-img img.back {
        height: 20px;
    }
    .random-no-wrapper .last-result-no {
        font-size: 20px;
        margin-top: -48px;
    }
    .random-shoot-wrapper {
        margin-top: -15px;
    }
    a.btn-rule {
        padding: 10px 15px;
    }
    img.icon {
        margin-bottom: 0;
    }
    /* /aungparzay/shoot/:id end */
    /* result page */
    .win-amount-status, .grand {
        font-size: 20px;
    }
    span.rubin {
        font-size: 18px;
    }
    img.win-img {
        height: 22px;
    }
    .left-card {
        margin-left: 30px;
    }
    .onecard-result {
        line-height: 1.8rem;
    }
    span.today-card-no {
        font-size: 22px;
    }
    span.today-card-time {
        font-size: 17px;
    }
    .today-card-results {
        height: 110px;
    }
    .rule-btn .btn {
        padding-left: 5px;
        padding-right: 5px;
    }
} 

@media (min-width: 376px) and (max-width: 390px) {
    .close-section-content .result-number {
        width: 62px;
        height: 39px;
    }
    p.title-result-section {
        padding: 7.5px 0;
    }
    img.img-playnow {
        height: 40px;
    }
}

@media only screen and (max-width: 375px)
{
    .timer-wrap {
        width: 82%;
        /* height: 90px; */
    }
    .align-center.left.grid-40.parent-ui.pocket {
        margin-left: 25px;
    }
    .align-center.left.grid-40.l1ogo.parent {
        margin-left: 38px;
    }
    .align-center.left.grid-20.parent.parent-flex img.pointer {
        height: 45px;
    }
    img.round-img1.pocket-icon {
        height: 35px;
    }
    .parent-ui.top-logo img.round-img1 {
        height: 45px;
    }
    img.pointer.pl-10 {
        height: 44px;
    }
    p.random-text {
        padding: 7.5px 0px;
    }

    .consetell-inner-r {
        padding: 8px 10px;
    }
    .fast-chose-txt {
        font-size: 16px;
        padding: 6px;
    }
    .last-timer {
        padding: 4px 0 4px;
    }

    img.img-playnow {
        height: 37px;
    }

    .timer-wrap > h2 {
        font-size: 20px;
        padding: 9.5px 0 4px 0;
    }

    .btn-text {
        padding: 5px 0;
    }

    p.lotto-g-no {
        margin-left: 35px;
    }
    p.type-shoot-number {
        top: 46%;
        left: 40%;
        font-weight: 1000;
        /* color: #e8d801; */
        color: #fff;
    }

    .lotto-wrapper {
        margin-bottom: 160px;
        margin-left: 7px;
    }
    .lotto {
        margin: 20px 0;
    }
    .aungparzay-icon {
        width: 50%;
    }
    .two-wrap .result-number {
        padding: 4px 15px;
    }
    h3.result-number {
        font-size: 18px;
    }
    .three-wrap .result-number {
        padding: 4px 12.5px;
    }
    p.title-result-section {
        padding: 4px 0;
    }
    p.title-result-section {
        font-size: 9px;
    }
    label {
        line-height: 30px;
    }
    * {
        font-size: small;
    }
    a.btn-rule {
        padding: 10px;
    }
    span.last-timer, .closetime-text {
        font-size: 14px !important;
    }
    span.timer {
        margin-top: 0;
    }
    /* result page */
    span.today-card-no {
        font-size: 20px;
    }
    img.win-img {
        height: 20px;
        margin-left: 20px;
    }
    span.today-card-time, span.lobby-check {
        font-size: 15px;
    }
    span.rubin {
        font-size: 17px;
    }
    .win-amount-status, .grand {
        font-size: 19px;
    }
    .ticket-check {
        padding: 0 10px !important;
    }
    .today-card-results {
        height: 105px;
    }
    .left-card {
        margin-left: 20px;
    }
    .single-shoot-game > p {
        font-size: 18px;
    }
    .rule-btn .btn-bet {
        padding: 10px 10px;
        font-size: 14px;
    }

}

@media only screen and (max-width: 330px) {
    p.lotto-g-no {
        position: absolute;
        margin-left: 0;
        width: 53px;
        height: 48px;
        top: 10px;
        font-size: 13px !important;
        left: 30%;
        padding: 5px 4px;
    }
    .mid-pocket {
        padding-right: 5px;
    }
    img.round-img1.pocket-icon {
        height: 32px;
    }
    .pocket span {
        font-size: 16px;
    }
    .pocket-plus-icon {
        height: 24px;
    }
    img.img-playnow {
        height: 33px;
    }
    .timer-wrap {
        padding: 5px 10px;
    }
}

@media (min-width: 376px) and (max-width: 400px) {
    p.type-shoot-number {
        top: 47px;
        left: 26px;
    }
}

@media (min-width: 401px) and (max-width: 425px) {
    p.type-shoot-number {
        top: 47px;
        left: 28px;
    }
}

@media (min-width: 426px) and (max-width: 450px) {
    p.type-shoot-number {
        left: 29px;
    }
}

@media (min-width: 331px) and (max-width: 350px) {
    /* .timer-wrap {
        width: 120px;
    } */
    .lotto-wrapper {
        margin-bottom: 160px;
    }
    img.img-playnow {
        height: 36px;
    }
    p.lotto-g-no {
        position: absolute;
        left: 30%;
        width: 50px;
        height: 50px;
        font-size: 13px;
        top: 10px;
        padding: 5px;
        margin-left: 0 !important;
    }
    .lotto-wrapper {
        margin-left: 7px;
    }
    .timer-wrap > span, .timer-wrap .closetime-text {
        margin-left: 5px;
    }
}

@media (min-width: 351px) and (max-width: 375px) {
    p.lotto-g-no {
        position: absolute;
        margin-left: 0;
        width: 53px;
        height: 48px;
        top: 10px;
        font-size: 13px !important;
        left: 30%;
        padding: 5px 4px;
    }
    .timer::before {
        top: 28px;
    }
    span.timer {
        margin-top: 0;
    }
}

/* responsive end */

/* news start */

.news-right {
    margin-right: 20px;
}

.news-section {
    margin: 20px 30px;
    padding: 20px;
    border: 1px solid #640105;
    border-radius: 12px;
    background: #640105;
}

.news-title {
    font-size: 15px;
}

p.news-text {
    font-size: 14px;
}
/* news end */

/* rules start */
.title-wrapper {
    margin: 30px 0;
}
.rules-section {
    margin: 20px 15px;
}
.rules-right {
    margin-right: 20px;
    margin-top: 5px;
}
.rules-right > p {
    padding: 8px 13px;
    border: 1px solid #F6D055;
    border-radius: 8px;
    background: #590100;
}
.rules-section-divider {
    position: relative;
}
.rules-section-divider::before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    height: 1.4px;
    bottom: 0;
    top: 150px;
    background: radial-gradient(100% 7111110.77% at 0% 50.01%, rgba(247, 208, 85, 0) 0%, #F7D055 49.48%, rgba(247, 208, 85, 0) 100%);
}
.gawlipit-section {
    margin-top: 50px;
    margin-bottom: 30px;
}
.gawli-rule-right {
    margin-top: 0;
}
/* rules end */

/* fast keyboard start */
.btn-no-keyboard {
    border: none;
    box-shadow: unset;
    background: var(--aungparzaybtncolor);
    border-radius: 8px;
}

.random-keyboard-wrapper .btn-bet {
    width: 100%;
    border: none;
}

.fast-keyboard-wrap {
    margin: 0 10px;
    padding: 20px 0 0px 0;
    background: #640105;
    border-radius: 20px;
    border: 2px solid #FE3B37;
}

.choose-number {
    justify-content: space-between;
    margin: 0 10px 20px 20px;
}

.choose-number h3.timer {
    position: relative;
    display: inline-block;
    color: #EB9939;
}

.random-keyboard-wrapper {
    margin-top: -30px;
    padding: 60px 20px 20px 20px;
    background: #2B0000;
    border-radius: 12px 12px 20px 20px;
}

p.choose-no-key {
    font-weight: 700;
    font-size: 26px;
    margin-top: -60px;
    color: #44EC0F;
    text-shadow: -1px -2px 1px rgba(89,1,0,.8), -1px 1px 0px rgb(156 0 22 / 80%);
    margin: 0 30px;
    background: #630004;
    padding: 10px 25px;
    border-radius: 12px;
    border: 2px solid #EC9A3A;
}

.number-bychoice {
    margin: 10px 5px 80px 5px;
    width: 100%
}

.bychoice-inner {
    padding: 5px 0px;
    background: #2B0000;
    margin: 5px 5px;
    border-radius: 6px;
    border: 1px solid #F6D055;
    float: left;
    font-family: 'Rubik';
    font-weight: 600;
}
/* fast keyboard end */

/* bet2d page start */
.two-game-wrapper i {
    cursor: pointer;
}
.consetell-inner {
    margin-right: 10px;
}
.consetell-inner > span {
    width: 100%;
}
.consetell-inner-r {
    padding: 12px 10px;
}
/* end bet2d page */