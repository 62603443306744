.color-text {
    color : #121826;
}

.color-white {
    color : #fff;
}

.color-gray {
    color : #e2e2e2;
}
.walletcolor{
    color:#FFE200;
    font-family: 'Myanmar Sangam MN';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}

.firstblock{
    border:1px solid slategrey;
}
.secondBlock{
    display: grid;
    grid-template-columns: 2fr 2fr;
    /* text-align: center; */
}
.Copy{
    padding: 10px;
    background-color: #FFFFFF;
    color: black;
    border: 1px solid;
    border-radius: 10px;
    margin-left: 20px;
}

.color-slight-gray {
    background : #f2f3f5;
}

.color-dark-yellow {
    color : yellow;
}
.color-dark-gray {
    color : rgb(136, 136, 136);
}

.color-dark-white {
    color : #a8a8a8;
}

.color-milk {
    color : #00f99b;
}

.color-black {
    color : #000 !important;
}

.color-dark-blue {
    color : #100a4a !important;
}

.color-blue {
    color: hsl(214, 89%, 52%);
}


.color-red {
    color : #f24848;
}

.color-dark-red {
    color : #870000;
}

.color-yellow {
    color : #FFE200;
}

.color-gold {
    color : #FFDF00 !important;
}

.color-orange {
    color : #FFA500;
}

.color-theme-gold {
    color : #FFE200;

}
.color-theme-divone {
    color : #FFE200;
    font-size: 35px;
}
.color-theme-divtwo {
    color : #FFE200;
    font-size: 35px;
}

.color-green {
    color : #00FF00;
}

.color-green-light {
    color : #00C134;
}

.color-gray {
    color: #e2e2e2;
}

.bg-second {
    background:  #006290;
}

.bg-green {
    background : #00FF00;
}

.bg-yellow {
    background : #FFE200;
}

.bg-theme-gold {
    background : #FFE200;
}

.bg-red {
    background : #f24848;
}

.bg-dark-red {
    background : #870000;
}

.bg-green-light {
    background : #00C134;
}

.bg-white {
    background: #fff;
}

.bg-slight-gray {
    background : #f2f3f5;
}

.bg-light-gray {
    background: #f6f6f6;
}

.bg-gray {
    background: #e2e2e2;
}

.bg-blue {
    background: rgb(48, 70, 228);
}

.bg-black {
    background : #000 !important;
}

.bg-light-purple {
    background : var(--primarythirdcolor);
}

.bg-aungparzay-darkred {
    background: var(--aungparzaybtncolor);
    border-radius: 8px;
    border: none;
}

.bg-image {
    background: url('/img/bg-pattern3.jpg');
}

.bg-noise {
    background: url(/img/noise.png);
    background-position: center;
    background-size: cover;
    /* margin: 60px 0; */
    padding-bottom: 20px;
}

.winner-lists {
    justify-content: space-around;
    align-items: center;
}

.faq-style-wrapper {
    padding: 0 20px;
}

span.icon-wrapper.styles_icon-wrapper__2cftw {
    color: #FFE200!important;
}

.winning-title {
    justify-content: center;
}

/* .bg-noise {
    background: url('/img/noise.png');
    background-position: center;
    background-size: cover;
    margin-bottom: 30px;
    mix-blend-mode: overlay;
    opacity: 0.08;
} */

.btn-blue {
    color: #fff !important;
    background: rgb(48, 70, 228) !important;
}
